import { ExternalLink } from 'lucide-react'
import { FC } from 'react'

import zoomSDK from '@zoom/appssdk'

import SettingsLayout from '@/components/layout/settings-layout'
import SettingsTitle from '@/components/settings-title'
import useClaims from '@/hooks/useClaims'
import useObject from '@/hooks/useObject'
import { accountDeveloperSettings } from '@/thunks/accounts/developer'

import APIKeysCard from './APIKeysCard'
import WebhookSecretCard from './WebhookSecret'
import { CopyButton } from '@/components/copy-button'

const Developers: FC = () => {
  const [settings] = useObject(accountDeveloperSettings, {})
  const claims = useClaims()

  if (!settings) return null

  return (
    <SettingsLayout>
      <SettingsTitle title="Developers" />
      <div className="space-y-8">
        <APIKeysCard {...settings} />
        <WebhookSecretCard {...settings} />
      </div>
      <div className="relative max-w-lg">
        <div className="flex justify-between items-center bg-background mt-4 px-3 py-2 border rounded-md text-sm">
          <span className="text-muted-foreground">Account ID:</span>
          <div className="flex items-center space-x-2">
            <code className="font-mono text-sm">{claims['acc']}</code>
            <CopyButton text={claims['acc']} />
          </div>
        </div>
      </div>
      {window.isZoom && (
        <div className="flex justify-end space-x-4 pr-2 max-w-lg text-muted-foreground text-xs">
          <div
            className="group flex space-x-2 hover:text-foreground cursor-pointer"
            onClick={() =>
              zoomSDK.openUrl({
                url: window.location.href + '?token=' + localStorage.getItem('token'),
              })
            }
          >
            <span>Open in browser</span>
            <ExternalLink className="group-hover:text-muted-foreground w-4 h-4 text-muted-foreground/50" />
          </div>
        </div>
      )}
    </SettingsLayout>
  )
}

export default Developers
