import { ExternalLink, Info, InfoIcon, Search } from 'lucide-react'
import { FC } from 'react'

import Field from '@/components/field'
import LoadingButton from '@/components/loading-button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Separator } from '@/components/ui/separator'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { ActionTimer } from '@/hooks/useActionTimer'

import { FormData } from './'
import HeadersForm from './HeadersForm'
import RemoveButton from './RemoveButton'

type Props = {
  timer: ActionTimer
  onRemove: () => void
}

const FormCard: FC<Props> = ({ timer, onRemove }) => {
  return (
    <Card className="max-w-lg">
      <CardHeader className="pb-4">
        <CardTitle className="flex items-center gap-3 text-lg">
          <Search className="w-4 h-4 text-primary" />
          Lookup API Configuration
        </CardTitle>
        <CardDescription className="text-sm">
          Configure your API endpoint and gjson paths to get customer data
        </CardDescription>
      </CardHeader>

      <Separator />

      <CardContent className="space-y-6 pt-4">
        <section>
          <div className="space-y-2">
            <Field<FormData> label="API Endpoint URL" name="url" />
            <Field<FormData> label={false} name="method">
              {field => (
                <RadioGroup
                  className="flex items-center gap-4 pl-2"
                  onValueChange={field.onChange}
                  value={field.value as string}
                >
                  <div className="flex items-center space-x-2 cursor-pointer">
                    <RadioGroupItem value="GET" id="GET" />
                    <Label htmlFor="GET" className="flex items-center cursor-pointer">
                      GET
                      <TooltipProvider delayDuration={0}>
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoIcon className="ml-1 w-3 h-3" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <code>?phoneNumber=13035551234</code>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2 cursor-pointer">
                    <RadioGroupItem value="POST" id="POST" />
                    <Label htmlFor="POST" className="flex items-center cursor-pointer">
                      POST
                      <TooltipProvider delayDuration={0}>
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoIcon className="ml-1 w-3 h-3" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <code>{'{ "phoneNumber": "+13035551234" }'}</code>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                  </div>
                </RadioGroup>
              )}
            </Field>

            <HeadersForm />
          </div>
        </section>

        {/* Data Mapping Section */}
        <section className="space-y-4 bg-muted/30 p-4 border rounded-lg">
          <div>
            <Label className="font-semibold">Data Mapping</Label>
            <p className="text-muted-foreground text-xs">
              Configure how customer data should be extracted and displayed
            </p>
          </div>

          <div className="space-y-3">
            <Field<FormData>
              label="Customer Path"
              name="customerPath"
              placeholder="data.customers"
              aside={
                <a
                  href="https://github.com/tidwall/gjson?tab=readme-ov-file#path-syntax"
                  target="_blank"
                  rel="noreferrer"
                  className="flex gap-1 text-muted-foreground text-xs hover:underline"
                >
                  View GJSON path syntax <ExternalLink className="w-3 h-3" />
                </a>
              }
            />

            <Field<FormData> label="Customer ID Path" name="customerId" placeholder="id" />

            <Field<FormData>
              label={
                <span>
                  Title Template
                  <DynamicContentTooltip />
                </span>
              }
              name="titleTemplate"
              placeholder="{firstName} {lastName}"
            />

            <Field<FormData>
              label={
                <span>
                  Description Template
                  <DynamicContentTooltip />
                </span>
              }
              name="descriptionTemplate"
              placeholder="{streetName} {zipCode}"
            />
          </div>
        </section>
      </CardContent>

      <CardFooter className="flex justify-between items-center bg-muted/30 py-3 border-t">
        <RemoveButton onRemove={onRemove} />
        <LoadingButton timer={timer} type="submit" variant="default">
          Save configuration
        </LoadingButton>
      </CardFooter>
    </Card>
  )
}

export default FormCard

const DynamicContentTooltip: FC = () => (
  <TooltipProvider delayDuration={0}>
    <Tooltip>
      <TooltipTrigger tabIndex={-1}>
        <Info className="ml-2 w-3 h-3" />
      </TooltipTrigger>
      <TooltipContent>
        <p>Use {'{fieldName}'} for dynamic content.</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
)
