import { FC, ReactNode, useState } from 'react'
import { Check, Copy } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import useTimer from '@/hooks/useTimer'
import clsx from 'clsx'
import { delay } from '@/hooks/useActionTimer'

type Props = {
  text: string
  children?: ReactNode
}

export const CopyButton: FC<Props> = ({ text, children }) => {
  const [wasCopied, start] = useTimer()
  const [isOpen, setIsOpen] = useState(false)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text)
      start()
      setTimeout(() => {
        setIsOpen(false)
      }, delay - 300)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  const defaultIcon = wasCopied ? (
    <Check className="w-4 h-4 text-green-400" />
  ) : (
    <Copy className="w-4 h-4 text-muted-foreground hover:text-foreground" />
  )

  return (
    <TooltipProvider>
      <Tooltip open={wasCopied || isOpen} delayDuration={0}>
        <TooltipTrigger
          className="flex justify-center items-center hover:bg-muted p-2 rounded"
          onClick={handleCopy}
          onMouseEnter={() => !wasCopied && setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {children ?? defaultIcon}
        </TooltipTrigger>
        <TooltipContent
          className={clsx({
            'text-white bg-green-400': wasCopied,
          })}
        >
          <p>{wasCopied ? 'Copied!' : 'Click to copy'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
