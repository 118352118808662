import { Eye, EyeOff } from 'lucide-react'
import { FC, useState } from 'react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

type Props = {
  isVisible: boolean
  onToggle: () => void
}

export const RevealButton: FC<Props> = ({ isVisible, onToggle }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <TooltipProvider>
      <Tooltip open={isOpen} delayDuration={0}>
        <TooltipTrigger
          className="flex justify-center items-center hover:bg-muted p-2 rounded"
          onClick={onToggle}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {isVisible ? (
            <EyeOff className="w-4 h-4 text-muted-foreground hover:text-foreground" />
          ) : (
            <Eye className="w-4 h-4 text-muted-foreground hover:text-foreground" />
          )}
        </TooltipTrigger>
        <TooltipContent>
          <p>{isVisible ? 'Hide' : 'Reveal'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
