import { FC, useState } from 'react'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { CopyButton } from '@/components/copy-button'
import { RevealButton } from '@/components/reveal-button'

type Props = {
  webhookSecret: string
}

const WebhookSecretCard: FC<Props> = ({ webhookSecret }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <Card className="flex flex-col shadow-none min-w-md max-w-lg">
      <CardHeader className="pb-4">
        <CardTitle>Webhook Secret</CardTitle>
        <CardDescription>
          This secret is used to verify the authenticity of incoming webhook requests. An{' '}
          <span className="font-mono">X-Signature</span> header will be included with webhook requests.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col flex-grow overflow-auto">
        <div className="flex items-center gap-2">
          <Input
            type="text"
            value={isVisible ? webhookSecret : webhookSecret.replace(/_(.+)$/, () => `_${'*'.repeat(32)}`)}
            readOnly
            className="bg-muted-foreground/5 font-mono truncate"
          />
          <RevealButton isVisible={isVisible} onToggle={() => setIsVisible(!isVisible)} />
          <CopyButton text={webhookSecret} />
        </div>
        <div className="mt-4">
          <Button size="sm" variant="outline">
            View code examples to verify the signature
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

export default WebhookSecretCard
