import clsx from 'clsx'
import { AlertCircle, Info } from 'lucide-react'
import { FC, useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { PhoneInput } from '@/components/global/phone-input'
import LoadingButton from '@/components/loading-button'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

import { FormData } from '.'
import useTestLookup from './useTestLookup'

export type Customer = {
  id: string
  title: string
  description: string
}

const TestSheet: FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const form = useFormContext<FormData>()

  const [customers, test, { timer, error }] = useTestLookup()
  const handleTest = useCallback(async () => {
    const isValid = await form.trigger()
    if (!isValid) return

    const data = form.getValues()
    await test(phoneNumber, data)
  }, [phoneNumber, form, test])

  return (
    <div className="top-10 right-0 bottom-0 fixed flex flex-col bg-background border-l w-[30rem]">
      <div className="flex flex-col p-6 h-full">
        <div className="mb-6">
          <h2 className="font-semibold text-lg">Test your API</h2>
          <p className="text-muted-foreground text-sm">Verify your customer lookup settings</p>
        </div>

        <div className="flex flex-col flex-grow space-y-4">
          <div className="flex gap-1 pb-1">
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Info className="w-3 h-3" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>E.164 format</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <PhoneInput
            id="phoneNumber"
            value={phoneNumber}
            onChange={setPhoneNumber}
            type="tel"
            placeholder="+1 234 567 8900"
            pattern="\+[0-9]{1,3}[0-9]{4,14}"
            title="Phone number in E.164 format (e.g., +1 234 567 8900)"
          />

          <div className="flex-grow bg-muted/10 p-4 border rounded-md overflow-auto">
            <h3 className="font-semibold text-md">Agent preview</h3>
            <p className="text-muted-foreground text-sm">The data below is what your agent will see</p>
            <Separator className="my-4" />

            <div className="space-y-2">
              {timer.isLoading ? (
                <>
                  {[...Array(3)].map((_, idx) => (
                    <div key={idx} className="flex items-center space-x-4 py-2 pl-2">
                      <Skeleton className="rounded-full w-12 h-12" />
                      <div className="space-y-2">
                        <Skeleton className="w-[250px] h-4" />
                        <Skeleton className="w-[200px] h-4" />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div key={customers?.length}>
                  {(customers ?? []).map(customer => (
                    <div
                      key={customer.id}
                      className={'flex items-center space-x-4 px-4 py-2 rounded-md justify-between hover:bg-accent'}
                    >
                      <div>
                        <p className="font-medium text-sm leading-none">{customer.title}</p>
                        <p className="text-muted-foreground text-sm">{customer.description}</p>
                      </div>
                      <div
                        className={clsx('text-sm', {
                          'text-red-700 font-medium text-sm': !customer.id,
                          'text-muted-foreground text-xs': customer.id,
                        })}
                      >
                        {customer.id ? `ID: ${customer.id}` : 'NO ID'}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {error && (
            <Alert className="bg-red-50 border-red-700">
              <AlertCircle className="w-4 h-4" />
              <AlertTitle className="text-red-800">Customer Lookup Failed</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {customers?.length === 0 && (
            <Alert className="border-orange-700 bg-orange-50">
              <AlertCircle className="w-4 h-4" />
              <AlertTitle className="text-orange-800">No Customers Found</AlertTitle>
              <AlertDescription>
                The API call was successful, but no customer records were found using the customer path.
              </AlertDescription>
            </Alert>
          )}
        </div>

        <div className="flex justify-between items-center gap-4 py-4">
          <span className="text-muted-foreground text-sm">{!phoneNumber && 'Enter a phone number above'}</span>
          <LoadingButton timer={timer} disabled={!phoneNumber} onClick={handleTest}>
            Test Customer Lookup
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default TestSheet
