import { FC, useState } from 'react'

import { Separator } from '@radix-ui/react-select'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { CopyButton } from '@/components/copy-button'
import { RevealButton } from '@/components/reveal-button'

type Props = {
  widgetKey: string
  secretKey: string
}

const APIKeysCard: FC<Props> = ({ widgetKey, secretKey }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <Card className="flex flex-col shadow-none min-w-md max-w-lg">
      <CardHeader>
        <CardTitle>API Credentials</CardTitle>
        <CardDescription>
          Use these credentials with our API. View <a href="https://journeyid.com">our documentation</a> for guides and
          an API reference.
        </CardDescription>
      </CardHeader>
      <Separator className="mb-4" />
      <CardContent className="flex flex-col flex-grow overflow-auto">
        <div className="space-y-6">
          <div>
            <label>Widget Key</label>
            <div className="flex items-center gap-2">
              <Input value={widgetKey} readOnly className="bg-muted-foreground/5 font-mono" />
              <CopyButton text={widgetKey} />
            </div>
            <div className="mt-2 text-muted-foreground text-xs">
              This key can be used for API requests from the browser, typically from a widget on the Agent Desktop.
            </div>
          </div>
          <div>
            <label>Secret Key</label>
            <div className="flex items-center gap-2">
              <Input
                type="text"
                value={isVisible ? secretKey : secretKey.replace(/_.+/, match => '_' + '*'.repeat(match.length - 1))}
                readOnly
                className="bg-muted-foreground/5 font-mono"
              />
              <RevealButton isVisible={isVisible} onToggle={() => setIsVisible(!isVisible)} />
              <CopyButton text={secretKey} />
            </div>
            <div className="mt-2 text-muted-foreground text-xs">
              This key is used for server-to-server API requests. This API key has full access to your account and must
              be kept secure.
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default APIKeysCard
