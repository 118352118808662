import { format } from 'date-fns'
import { FC } from 'react'

import { CopyButton } from '@/components/copy-button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Admin } from '@/watch-tower/out/admin'

type Props = {
  admins: Admin[]
}

const AdminsTable: FC<Props> = ({ admins }) => {
  return (
    <div className="border rounded-md max-w-4xl">
      <Table>
        <TableHeader>
          <TableRow className="bg-muted/30 hover:bg-muted/30 font-semibold text-foreground">
            <TableHead className="pl-6">Admin</TableHead>
            <TableHead>Added</TableHead>
            <TableHead>Timezone</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {admins.map(admin => (
            <TableRow key={admin.id} className="hover:bg-inherit transition-colors">
              <TableCell className="pl-4">
                <div className="flex items-center space-x-4">
                  {admin.photo ? (
                    <img src={admin.photo} className="rounded w-12 h-12" />
                  ) : (
                    <div className="bg-muted/50 rounded w-12 h-12" />
                  )}
                  <div className="flex flex-col">
                    <span className="font-medium">
                      {admin.firstName} {admin.lastName}
                    </span>
                    <div className="relative text-muted-foreground text-sm">
                      {admin.email}
                      <CopyButton text={admin.email} />
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell>{format(new Date(), 'MMM d, yyyy')}</TableCell>
              <TableCell>{admin.timeZone ?? <span className="text-muted-foreground">unknown</span>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AdminsTable
